import SkeletonItem from 'components/skeleton/SkeletonItem';
import { TestIdsCommon } from 'constants/e2eIds/common-test-ids';

const AppSmartBannerContentSkeleton = () => {
  return (
    <>
      <div
        className="flex-1"
        data-test-id={TestIdsCommon.SKELETON}
      >
        <SkeletonItem className="mb-1 h-2 w-5/6" />
        <SkeletonItem className="h-2 w-1/3" />
      </div>
      <SkeletonItem className="!h-4 w-14" />
    </>
  );
};

export default AppSmartBannerContentSkeleton;
