import clsx from 'clsx';
import { memo } from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;
  repeat?: number;
  style?: React.CSSProperties;
}

export const SkeletonItem = ({
  width = 'w-full',
  height = 'h-2.5',
  className,
  repeat = 1,
  style,
}: Props) => {
  return (
    <>
      {[...Array(repeat).keys()].map((_, idx) => (
        <div
          key={idx}
          className={clsx('rounded-lg bg-grey-20', width, height, className)}
          style={style}
        />
      ))}
    </>
  );
};

export default memo(SkeletonItem);
